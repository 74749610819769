import React from "react";
import styled from "styled-components";
import Toggle from "react-toggle";

const Table = styled.table`
  width: 100%;

  td {
    padding: 10px 0px;
    vertical-align: middle;
  }
`;

const Container = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  padding 20px;
  border-radius: 8px;
`;

const Rewards = () => (
  <>
    <h4>Rewards</h4>
    <p>
      Welcome to your rewards! Here you can track your progress towards home
      unlocks and opt into receive better deals on key expenses related to your
      home.
    </p>
    <Container>
      <strong>What offers you are interested in?</strong><br/><br/>
      <Table>
        <tr>
          <td>Internet and phone</td>
          <td width="50px">
            {" "}
            <Toggle />
          </td>
        </tr>
        <tr>
          <td>Electricity and gas</td>
          <td width="50px">
            {" "}
            <Toggle />
          </td>
        </tr>
        <tr>
          <td>Insurance</td>
          <td width="50px">
            {" "}
            <Toggle />
          </td>
        </tr>
        <tr>
          <td>Your home loan</td>
          <td width="50px">
            {" "}
            <Toggle />
          </td>
        </tr>
        <tr>
          <td>Real estate agents</td>
          <td width="50px">
            {" "}
            <Toggle />
          </td>
        </tr>
      </Table>
    </Container>
    <br/>
    <Container>
      <strong>How would you like to receive these offers?</strong><br/><br/>
      <Table>
        <tr>
          <td>Phone call</td>
          <td width="50px">
            {" "}
            <Toggle/>
          </td>
        </tr>
        <tr>
          <td>SMS</td>
          <td width="50px">
            {" "}
            <Toggle />
          </td>
        </tr>
        <tr>
          <td>Email</td>
          <td width="50px">
            {" "}
            <Toggle />
          </td>
        </tr>
        <tr>
          <td>Via this app</td>
          <td width="50px">
            {" "}
            <Toggle />
          </td>
        </tr>
     
      </Table>
    </Container>
  </>
);

export default Rewards;
