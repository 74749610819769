import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";
import StarRating from "../Components/StarRating";
import fetchHealthScore from "../Services/fetchHealthScore";
import {
  propertyTypeOptions,
  propertyFoundationOptions,
  propertyWallMaterialOptions,
  propertyRoofMaterialOptions,
  propertyConstructionStandardOptions,
  propertySlopeOfLandOptions,
  propertyNumberOfLevelsOptions,
  propertyNumberOfBedroomsOptions,
  propertySizeOfMainBedroomOptions,
  propertyNumberOfBathroomsOptions,
  propertyNumberOfCoveredCarSpacesOptions,
} from "../Utils/formOptions";

const Score = styled.div`
  color: #0047e3;
  font-size: 5rem;
  line-height: 5rem;
  font-weight: bold;
  text-align: center;
`;

const ScoreSummary = styled.div`
  color: #888;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;

const Scale = styled.div`
  width: 160px;
  height: 24px;
  background-color: #ccc;
  margin: 0 auto;
  border-radius: 4px;
  text-align: center;
  color: #888;
`;

const PropertyClaimForm = styled.div`
  background-color: #ccc;
  margin: 0 auto;
  border-radius: 4px;
  width: 100%;
  height: 120px;
  max-width: 420px;
  color: #888;
`;

const Renter = ({ scoreInput }) => {
  const [score, setScore] = useState("");
  const [confidence, setConfidence] = useState("");

  useEffect(() => {
    fetchHealthScore(scoreInput)
      .then((response) => {
        console.log(response);
        setScore(response.result.toFixed(1));
        setConfidence(response.confidence.toFixed(1));
      })
      .catch((err) => {
        console.error({ err });
      });
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h4>Property Health Score for:</h4>
          <p>14 Ryrie Street, Neutral Bay, NSW, 2089</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Score>{score}</Score>
          <ScoreSummary>out of 10</ScoreSummary>
          <br />
          <Scale>(confidence: {confidence})</Scale>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <p>
            The Health Score for this property is low|medium|high at {score}{" "}
            based on available data. More information is needed to provide a
            more accurate Score.
          </p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <PropertyClaimForm>(Claim this property form)</PropertyClaimForm>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Row>
            <Col>
              <h5>Rate this property</h5>
            </Col>
          </Row>
          <Row>
            <Col sm="3">Overall impression</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
          <Row>
            <Col sm="3">Quiet indoors</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
          <Row>
            <Col sm="3">Quiet outdoors</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
          <Row>
            <Col sm="3">Free of mould</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
          <Row>
            <Col sm="3">Plenty of natural light</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
          <Row>
            <Col sm="3">Quality fitout</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
          <Row>
            <Col sm="3">Well maintained</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
          <Row>
            <Col sm="3">Energy efficient</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
          <Row>
            <Col sm="3">Inexpensive to run</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
          <Row>
            <Col sm="3">Great views</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
          <Row>
            <Col sm="3">Security</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
          <Row>
            <Col sm="3">Safety</Col>
            <Col>
              <StarRating />
            </Col>
          </Row>
        </Col>
      </Row>
      <Button>Submit</Button>

      <Row>
        <Col>
          <Form>
            <Form.Group controlId="formPropertyType">
              <Form.Label>Building type</Form.Label>
              <Select placeholder="Select ..." options={propertyTypeOptions} />
            </Form.Group>
            <br />
            <Form.Group controlId="formBuildingFoundation">
              <Form.Label>Foundations</Form.Label>
              <Select
                placeholder="Select ..."
                options={propertyFoundationOptions}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formBuildingWallMaterial">
              <Form.Label>External wall material</Form.Label>
              <Select
                placeholder="Select ..."
                options={propertyWallMaterialOptions}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formBuildingRoofMaterial">
              <Form.Label>Roof material</Form.Label>
              <Select
                placeholder="Select ..."
                options={propertyRoofMaterialOptions}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formBuildingConstructionStandard">
              <Form.Label>Construction standard</Form.Label>
              <Select
                placeholder="Select ..."
                options={propertyConstructionStandardOptions}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formBuildingSlopeOfLand">
              <Form.Label>Slope of land</Form.Label>
              <Select
                placeholder="Select ..."
                options={propertySlopeOfLandOptions}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Col>
          <Form>
            <Form.Group controlId="formPropertyNumberOfLevels">
              <Form.Label>Number of levels</Form.Label>
              <Select
                placeholder="Select ..."
                options={propertyNumberOfLevelsOptions}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formNumberOfBedrooms">
              <Form.Label>Number of Bedrooms and Studies</Form.Label>
              <Select
                placeholder="Select ..."
                options={propertyNumberOfBedroomsOptions}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formSizeOfMainBedroom">
              <Form.Label>Size of main bedroom</Form.Label>
              <Select
                placeholder="Select ..."
                options={propertySizeOfMainBedroomOptions}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formNumberOfBathrooms">
              <Form.Label>Number of bathrooms</Form.Label>
              <Select
                placeholder="Select ..."
                options={propertyNumberOfBathroomsOptions}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formNumberOfCarSpaces">
              <Form.Label>Number of covered car spaces</Form.Label>
              <Select
                placeholder="Select ..."
                options={propertyNumberOfCoveredCarSpacesOptions}
              />
            </Form.Group>
            <br />
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Renter;
