import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: right;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2rem;   
  padding: 5px 20px;
  background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
  backdrop-filter: blur(10px);
  font-size: 0.8rem;
`;

const Footer = () => <Container><a href='/terms'>Terms & Conditions</a> | <a href='/privacy'>Privacy policy</a></Container>;

export default Footer;