import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const PropertyAddress = ({value, onChange}) => {
  return (<GooglePlacesAutocomplete
    selectProps={{
      placeholder: "Type address",
      value: value,
      onChange: onChange,
    }}
    autocompletionRequest={{
      componentRestrictions: {
        country: ["au"],
      },
    }}
    apiKey="AIzaSyCarFqICWsFqCI1Z-TVmQj_TY4opmxRdsY"
  />
)};

export default PropertyAddress;