/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-2",
  aws_cognito_identity_pool_id:
    "ap-southeast-2:5d1a9dd5-9d4b-41ae-a8f1-472e852b1f40",
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: "ap-southeast-2_8SEDRMibB",
  aws_user_pools_web_client_id: "50sthnspf3ss9bd1roak73r96u",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE", "FACEBOOK"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
