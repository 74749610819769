import React from 'react';
import Markdown from 'markdown-to-jsx';

const PropertyHealth= () => (<Markdown>{`# Feature: Property health

## **Have the finger on the pulse of your home with Property Logbook's 'Property health'.**

Whether you're searching to buy a new property, renting or living in your own place, keeping tabs on your property's health is essential. It is the difference between minor fixes and huge repair costs. The difference between a safe property or a huge accident when you least need it. Ultimately, the difference between a happy household and financial distress.

But don't worry, it's not as daunting as you think.  With Property Logbook, we've got you covered. Our suite of property health and maintenance tools make it easy to stay on top of your property health.

## **Property health status**

Using Property Logbook's proprietary database and world leading algorithms designed by our in-house building engineers, at the click of a button, you'll be provided with a property score for your asset or home.

You can then keep track of your health score overtime as we learn more about your property when you log. Fixed that rotted weatherboard out the back? Log it and see an increase in your score!

This data not only helps you keep track of your assets status, but you can even use it to indicate to other stakeholders the quality of your property!

**Environmental risk**

Knowing the risks associated with a plot of land when hunting for a new property is hard. Unless you pay for expensive experts, or happen to know someone in the know, it's basically impossible. Most of the time, new home owners don't even realise their new house is in a high risk zone until they apply for insurance!

With PLB, establishing the environmental risks associated with a property is a piece of cake. Tell us the property you're interested in, and we'll cross analyse it with our own data to tell you the key risks associated with the plot. Never get caught out again!

### **Maintenance log**

Keeping track of all the work (DIY and professional) over the course of owning a property is a pain.

Combined with our directory feature, we make it easy to log an issue, get it fixed and then record job completion all in one place.

With PLB you can keep track of everything going on across one or multiple properties. Save money on fixing issues and taking preventative action, and draw insights on your property, with the help of PLBs AI driven insight engines identify sticky issues before they even happen!

### **Service plan**

Budgeting for regular maintenance is cheaper and safer than waiting for small issues to accumulate into larger structural issues. Simple measures like monitoring moisture levels in the walls, roof maintenance, pest inspections cost very little but have the power to save you thousands.

Based on your specific property, we provide a Service Plan that allows you to budget and have peace of mind.`}</Markdown>);

export default PropertyHealth;