import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation, gql } from '@apollo/client';
import { Card, Modal, Row, Col, Form, Button } from 'react-bootstrap';
import {
  CalendarCheckFill,
  CalendarFill,
  PencilFill,
  CloudArrowUpFill,
} from 'react-bootstrap-icons';
import Select from 'react-select';
import ReactTagInput from '@pathofdev/react-tag-input';
import DatePicker from 'react-datepicker';
import Toggle from 'react-toggle';

import 'react-datepicker/dist/react-datepicker.css';
import TaskForm from './TaskForm';

const ADD_MAINTENANCE_LOG_ITEM = gql`
  mutation AddMaintenanceLogItem(
    $done_by: String!
    $propertyId: uuid!
    $title: String!
    $notes: String!
    $doneDate: date!
  ) {
    insert_maintenance_log_one(
      object: {
        done_by: $done_by
        propertyId: $propertyId
        title: $title
        notes: $notes
        done_date: $doneDate
      }
    ) {
      id
    }
  }
`;

const IconWrapper = styled.span`
  display: inline-block;
  margin-right: 8px;

  color: ${(props) => (props.done ? '#2caf01' : 'gray')};
`;

const FillButton = styled(Button)`
  width: 100%;
`;

const Container = styled.div`
  margin-bottom: 16px;
`;

const TaskContainer = styled(Card.Body)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleContainer = styled.div`
  display: flex;
`;

const Due = styled.div`
  margin-right: 16px;
  font-size: 0.8em;
`;

const ItemDetail = ({
  onHide,
  title,
  propertyId,
  setIsDone,
  isEditing,
  mlId,
  triggerRefetch
}) => {
  const [addMaintenanceLogItem] = useMutation(ADD_MAINTENANCE_LOG_ITEM);

  const [notes, setNotes] = useState('');
  const [doneBy, setDoneBy] = useState('');
  const [doneDate, setDoneDate] = useState(
    new Date().toISOString().split('T')[0]
  );

  const handleDone = async () => {
    await addMaintenanceLogItem({
      variables: {
        title: title,
        propertyId: propertyId,
        notes: notes,
        done_by: doneBy,
        doneDate: doneDate,
      },
    });
    setIsDone(true);
    triggerRefetch();
    onHide();
  };

 

  return (
    <>
      {!isEditing && (
        <>
          <Row>
            <Col>
              <Form.Control
                as='textarea'
                onChange={(evt) => {
                  setNotes(evt.target.value);
                }}
                placeholder='Notes...'
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Control
                type='date'
                onChange={(evt) => {
                  setDoneDate(evt.target.value);
                }}
                placeholder='Done date...'
                value={doneDate}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={8}>
              <Form.Control
                type='text'
                onChange={(evt) => {
                  setDoneBy(evt.target.value);
                }}
                placeholder='Done by...'
              />
            </Col>
            <Col>
              {' '}
              <FillButton variant='secondary'>
                <CloudArrowUpFill /> Upload Doc
              </FillButton>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12}>
              <FillButton onClick={handleDone}>Done this cycle!</FillButton>
            </Col>
            {/* <Col>
          {" "}
          <FillButton variant="secondary"  onClick={() => setIsEditing(true)}>
            <PencilFill />
          </FillButton>
        </Col> */}
          </Row>
        </>
      )}

      {isEditing && (
        <TaskForm onHide={onHide} title={title} propertyId={propertyId} mlId={mlId} triggerRefetch={triggerRefetch} />
      )}
    </>
  );
};

const MaintenanceLogItem = ({
  eventKey,
  title,
  alreadyDone,
  propertyId,
  dueDate,
  isEditing,
  mlId,
  triggerRefetch
}) => {
  const [isDone, setIsDone] = useState(alreadyDone);
  const [showModal, setShowModal] = useState(false);

  const handleToggle = (evt) => {
    if (evt.target.checked) {
      setShowModal(true);
    }
  };

  return (
    <Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          {' '}
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemDetail
            onHide={() => setShowModal(false)}
            title={title}
            propertyId={propertyId}
            setIsDone={setIsDone}
            isEditing={isEditing}
            mlId={mlId}
            triggerRefetch={triggerRefetch}
          />
        </Modal.Body>
      </Modal>
      <Card eventKey={eventKey}>
        <TaskContainer>
          <div>
            {/* <IconWrapper done={isDone}>
              {isDone ? (
                <CalendarCheckFill size={24} />
              ) : (
                <CalendarFill size={24} />
              )}
            </IconWrapper> */}
            {title}{' '}
          </div>
          {!isEditing && (
            <ToggleContainer>
              <Due>
                <strong>Due:</strong> {dueDate.toLocaleDateString()}
              </Due>
              <Toggle checked={alreadyDone} onClick={handleToggle} />
            </ToggleContainer>
          )}
          {isEditing && (
            <ToggleContainer>
              <Button onClick={() => setShowModal(true)} size='sm'>
                Edit
              </Button>
            </ToggleContainer>
          )}
        </TaskContainer>
      </Card>
    </Container>
  );
};

export default MaintenanceLogItem;
