import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { useMutation, gql, useApolloClient } from '@apollo/client';
import PropertySelector from '../Components/PropertySelector';

import Score from '../Components/Score';
import {
  propertyTypeOptions,
  propertyFoundationOptions,
  propertyWallMaterialOptions,
  propertyRoofMaterialOptions,
  propertyConstructionStandardOptions,
  propertyMaintenanceLevelOptions,
  propertySlopeOfLandOptions,
  propertyNumberOfLevelsOptions,
  propertyNumberOfBedroomsOptions,
  propertySizeOfMainBedroomOptions,
  propertyNumberOfBathroomsOptions,
  propertyNumberOfCoveredCarSpacesOptions,
} from '../Utils/formOptions';

const ScoreSummary = styled.div`
  color: #888;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;

const Scale = styled.div`
  width: 160px;
  height: 24px;
  background-color: #ccc;
  margin: 0 auto;
  border-radius: 4px;
  text-align: center;
  color: #888;
`;

const PropertyClaimForm = styled.div`
  background-color: #ccc;
  margin: 0 auto;
  border-radius: 4px;
  width: 100%;
  height: 120px;
  max-width: 420px;
  color: #888;
`;

const GET_PROFILE = gql`
  query GetPropertyProfile($propertyId: uuid!) {
    property_profile(where: { propertyId: { _eq: $propertyId } }) {
      building_type
      foundations
      external_wall_material
      roof_material
      construction_standard
      maintenance_level
      slope_of_land
      number_of_levels
      number_of_levels
      number_of_bedrooms
      size_of_main_bedroom
      number_of_bathrooms
      number_of_car_spaces
    }
  }
`;

const UPDATE_PROPERTY_PROFILE = gql`
  mutation UpdatePropertyMutation(
    $propertyId: uuid!
    $buildingType: String!
    $foundations: String!
    $externalWallMaterial: String!
    $roofMaterial: String!
    $constructionStandard: String!
    $slopeOfLand: String!
    $maintenanceLevel: String!
  ) {
    update_property_profile(
      where: { propertyId: { _eq: $propertyId } }
      _set: {
        building_type: $buildingType
        foundations: $foundations
        external_wall_material: $externalWallMaterial
        roof_material: $roofMaterial
        construction_standard: $constructionStandard
        slope_of_land: $slopeOfLand
        maintenance_level: $maintenanceLevel
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_PROPERTY_PROFILE_TWO = gql`
  mutation UpdatePropertyMutationTwo(
    $propertyId: uuid!
    $numberOfLevels: String!
    $numberOfBedrooms: String!
    $sizeOfMainBedroom: String!
    $numberOfBathrooms: String!
    $numberOfCarSpaces: String!
  ) {
    update_property_profile(
      where: { propertyId: { _eq: $propertyId } }
      _set: {
        number_of_levels: $numberOfLevels
        number_of_bedrooms: $numberOfBedrooms
        size_of_main_bedroom: $sizeOfMainBedroom
        number_of_bathrooms: $numberOfBathrooms
        number_of_car_spaces: $numberOfCarSpaces
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const PropertyProfile = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [score, setScore] = useState('7.0');
  const [confidence, setConfidence] = useState('Low');
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [refetch, setRefetch] = useState(0);

  const { step } = useParams();

  const [updatePropertyProfile, updateResponse] = useMutation(
    UPDATE_PROPERTY_PROFILE
  );
  const [updatePropertyProfileTwo, updateResponseTwo] = useMutation(
    UPDATE_PROPERTY_PROFILE_TWO
  );

  const getProfile = async (propertyId) => {
    const result = await client.query({
      query: GET_PROFILE,
      variables: {
        propertyId: propertyId,
      },
      fetchPolicy: 'network-only',
    });
    if (result?.data?.property_profile?.length) {
      setCurrentProfile(result.data.property_profile[0]);
    }
    console.log({ result });
    setRefetch(refetch + 1);
  };

  useEffect(() => {
    const _selectedPropertyId = localStorage.getItem('selectedProperty');
    setSelectedPropertyId(_selectedPropertyId);
  }, []);

  useEffect(() => {
    getProfile(selectedPropertyId);
  }, [selectedPropertyId]);

  const getFormOneData = () => {
    const initialValues = {
      buildingType: currentProfile ? currentProfile.building_type : '',
      foundations: currentProfile ? currentProfile.foundations : '',
      externalWallMaterial: currentProfile
        ? currentProfile.external_wall_material
        : '',
      roofMaterial: currentProfile ? currentProfile.roof_material : '',
      constructionStandard: currentProfile
        ? currentProfile.construction_standard
        : '',
      slopeOfLand: currentProfile ? currentProfile.slope_of_land : '',
      maintenanceLevel: currentProfile ? currentProfile.maintenance_level : '',
    };
    console.log({ initialValues });
    return initialValues;
  };

  const getFormTwoData = () => ({
    numberOfLevels: currentProfile ? currentProfile.number_of_levels : '',
    numberOfBedrooms: currentProfile ? currentProfile.number_of_bedrooms : '',
    sizeOfMainBedroom: currentProfile
      ? currentProfile.size_of_main_bedroom
      : '',
    numberOfBathrooms: currentProfile ? currentProfile.number_of_bathrooms : '',
    numberOfCarSpaces: currentProfile
      ? currentProfile.number_of_car_spaces
      : '',
  });

  const submitFormStepOne = async (formData) => {
    console.log(formData, selectedPropertyId);

    await updatePropertyProfile({
      variables: {
        propertyId: selectedPropertyId,
        buildingType: formData.buildingType,
        foundations: formData.foundations,
        externalWallMaterial: formData.externalWallMaterial,
        roofMaterial: formData.roofMaterial,
        constructionStandard: formData.constructionStandard,
        slopeOfLand: formData.slopeOfLand,
        maintenanceLevel: formData.maintenanceLevel,
      },
    });
    await getProfile(selectedPropertyId);
    navigate('/property-profile/2');
  };

  const submitFormStepTwo = async (formData) => {
    console.log(formData);
    await updatePropertyProfileTwo({
      variables: {
        propertyId: selectedPropertyId,
        numberOfLevels: formData.numberOfLevels,
        numberOfBedrooms: formData.numberOfBedrooms,
        sizeOfMainBedroom: formData.sizeOfMainBedroom,
        numberOfBathrooms: formData.numberOfBathrooms,
        numberOfCarSpaces: formData.numberOfCarSpaces,
      },
    });
    await getProfile(selectedPropertyId);
    navigate('/dashboard');
  };

  if (!currentProfile) return null;

  if (step === '1') {
    return (
      <>
        <Row>
          <Col>
            <PropertySelector
              onChange={(e) => setSelectedPropertyId(e.value)}
            />
            <hr />
            <Formik
              enableReinitialize={true}
              onSubmit={submitFormStepOne}
              initialValues={getFormOneData()}
              key={refetch}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId='formPropertyType'>
                      <Form.Label>Building type</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        options={propertyTypeOptions}
                        name='buildingType'
                        onChange={(e) =>
                          setFieldValue('buildingType', e.target.value)
                        }
                        required
                        defaultValue={values.buildingType}
                      >
                        <option value=''>Select ...</option>
                        {propertyTypeOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formBuildingFoundation'>
                      <Form.Label>Foundations</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        name='foundations'
                        onChange={(e) =>
                          setFieldValue('foundations', e.target.value)
                        }
                        required={true}
                        defaultValue={values.foundations}
                      >
                        <option value=''>Select ...</option>
                        {propertyFoundationOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formBuildingWallMaterial'>
                      <Form.Label>External wall material</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        options={propertyWallMaterialOptions}
                        name='externalWallMaterial'
                        onChange={(e) =>
                          setFieldValue('externalWallMaterial', e.target.value)
                        }
                        required
                        defaultValue={values.externalWallMaterial}
                      >
                        <option value=''>Select ...</option>
                        {propertyWallMaterialOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formBuildingRoofMaterial'>
                      <Form.Label>Roof material</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        options={propertyRoofMaterialOptions}
                        name='roofMaterial'
                        value={values.roofMaterial}
                        onChange={(e) =>
                          setFieldValue('roofMaterial', e.target.value)
                        }
                        required={true}
                        defaultValue={values.roofMaterial}
                      >
                        <option value=''>Select ...</option>
                        {propertyRoofMaterialOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formBuildingConstructionStandard'>
                      <Form.Label>Construction standard</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        options={propertyConstructionStandardOptions}
                        name='constructionStandard'
                        onChange={(e) =>
                          setFieldValue('constructionStandard', e.target.value)
                        }
                        required={true}
                        defaultValue={values.constructionStandard}
                      >
                        <option value=''>Select ...</option>
                        {propertyConstructionStandardOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formBuildingSlopeOfLand'>
                      <Form.Label>Slope of land</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        options={propertySlopeOfLandOptions}
                        name='slopeOfLand'
                        value={values.slopeOfLand}
                        onChange={(e) =>
                          setFieldValue('slopeOfLand', e.target.value)
                        }
                        required={true}
                        defaultValue={values.slopeOfLand}
                      >
                        <option value=''>Select ...</option>
                        {propertySlopeOfLandOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formBuildingMaintenanceLevel'>
                      <Form.Label>Maintenance level</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        options={propertyMaintenanceLevelOptions}
                        name='maintenanceLevel'
                        onChange={(e) =>
                          setFieldValue('maintenanceLevel', e.target.value)
                        }
                        required={true}
                        defaultValue={values.maintenanceLevel}
                      >
                        <option value=''>Select ...</option>
                        {propertyMaintenanceLevelOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Button type='submit'>Next</Button>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </>
    );
  }

  if (step === '2') {
    return (
      <>
        <Row>
          <Col>
            <PropertySelector
              onChange={(e) => setSelectedPropertyId(e.value)}
            />
            <hr />
            <Formik
              enableReinitialize={true}
              onSubmit={submitFormStepTwo}
              initialValues={getFormTwoData()}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId='formPropertyNumberOfLevels'>
                      <Form.Label>Number of levels</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        name='numberOfLevels'
                        value={values.numberOfLevels}
                        onChange={(e) =>
                          setFieldValue('numberOfLevels', e.target.value)
                        }
                        required={true}
                        defaultValue={values.numberOfLevels}
                      >
                        <option value=''>Select ...</option>
                        {propertyNumberOfLevelsOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formNumberOfBedrooms'>
                      <Form.Label>Number of Bedrooms and Studies</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        value={values.numberOfBedrooms}
                        name='numberOfBedrooms'
                        onChange={(e) =>
                          setFieldValue('numberOfBedrooms', e.target.value)
                        }
                        required={true}
                        defaultValue={values.numberOfBedrooms}
                      >
                        <option value=''>Select ...</option>
                        {propertyNumberOfBedroomsOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formSizeOfMainBedroom'>
                      <Form.Label>Size of main bedroom</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        value={values.sizeOfMainBedroom}
                        name='sizeOfMainBedroom'
                        onChange={(e) =>
                          setFieldValue('sizeOfMainBedroom', e.target.value)
                        }
                        required={true}
                        defaultValue={values.sizeOfMainBedroom}
                      >
                        <option value=''>Select ...</option>
                        {propertySizeOfMainBedroomOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formNumberOfBathrooms'>
                      <Form.Label>Number of bathrooms</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        value={values.numberOfBathrooms}
                        name='numberOfBathrooms'
                        onChange={(e) =>
                          setFieldValue('numberOfBathrooms', e.target.value)
                        }
                        required={true}
                        defaultValue={values.numberOfBathrooms}
                      >
                        <option value=''>Select ...</option>
                        {propertyNumberOfBathroomsOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formNumberOfCarSpaces'>
                      <Form.Label>Number of covered car spaces</Form.Label>
                      <Form.Control
                        as='select'
                        placeholder='Select ...'
                        value={values.numberOfCarSpaces}
                        name='numberOfCarSpaces'
                        onChange={(e) =>
                          setFieldValue('numberOfCarSpaces', e.target.value)
                        }
                        required={true}
                        defaultValue={values.numberOfCarSpaces}
                      >
                        <option value=''>Select ...</option>
                        {propertyNumberOfCoveredCarSpacesOptions.map((o) => (
                          <option key={o.label}>{o.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />
                    <Button type='submit'>Next</Button>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <PropertySelector onChange={(e) => setSelectedPropertyId(e.value)} />
      <br />
      <Row>
        <Col>
          <h4>Property Profile</h4>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Score propertyId={selectedPropertyId} />
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col>
          <h5>Benefits of your Property Health Score</h5><br/>
          <ul>
            <li>
              <strong>Unlock Financial Advantages:</strong> Gain access to
              exclusive, more favourable terms from insurers and banks.
            </li>
            <li>
              <strong>Efficient Tax Management:</strong> Easily generate
              comprehensive tax depreciation schedules, simplifying your
              financial planning and potentially reducing your tax burden.
            </li>
            <li>
              <strong>Secure, Cloud-Based Document Storage:</strong>{' '}
              Effortlessly store and protect important documents like plans,
              contracts, and receipts in a secure cloud environment.
            </li>
            <li>
              <strong>Efficient Document Retrieval:</strong> Quickly find
              exactly what you need, when you need it — from warranties to
              contracts — with our advanced search tools.
            </li>
            <li>
              <strong>Enhance Your Property's Marketability:</strong> A
              well-documented Property Logbook significantly increases buyer
              interest, leading to better sales results.
            </li>
            <li>
              <strong>Streamlined Maintenance Planning:</strong> Our service
              schedule feature allows for easy planning and budgeting of
              property maintenance tasks.
            </li>
            <li>
              <strong>Showcase Your Property's Health Score:</strong> Advertise
              your property's Health Score to potential buyers, demonstrating
              its excellent condition and value
            </li>
          </ul>

          <br />
          {/* <br />
          <Button
            onClick={() => {
              navigate("/property-profile/2");
            }}
          >
            (Temp link to Profile Form 2)
          </Button> */}
          <br />
          <br />
          <br />
        </Col>
      </Row>
    </>
  );
};

export default PropertyProfile;
