import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { PopupButton } from 'react-calendly';
import logo from '../logotype.svg';

const UAHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  align-items: center;

  div:last-of-type {
    display: flex;
    a:last-of-type {
      display: inline-block;
      padding: 10px 20px;
    }
  }
`;

const CalendlyButton = styled(PopupButton)`
  border: none;
  background: none;
  color: #0d6efd;
  text-decoration: underline;
`;

const AUTH_USER_TOKEN_KEY = 'PB_TOKEN';

const Logo = styled.img`
  margin-left: 20px;
`;

const Navigation = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);

  const getCurrentUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setCurrentUser(user);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const handleSignOut = async () => {
    await Auth.signOut();
    setCurrentUser(null);
    sessionStorage.removeItem(AUTH_USER_TOKEN_KEY);

    navigate('/login');
  };

  if (currentUser) {
    return (
      <Navbar bg='light'>
        <Navbar.Brand href='/'>
          <Logo src={logo} width='120' />
        </Navbar.Brand>
        <Nav style={{ marginRight: 20 }} className='ms-auto'>
          {!currentUser && (
            <>
              <Nav.Link href='/about'>About</Nav.Link>
              {/* <Nav.Link href="/signup">Sign Up</Nav.Link> */}
              <Nav.Link href='/login'>Log in</Nav.Link>
              {/* https://calendly.com/davetowey */}
            </>
          )}
          {currentUser && <Nav.Link onClick={handleSignOut}>Log out</Nav.Link>}
        </Nav>
      </Navbar>
    );
  }

  return (
    <UAHeader>
      <div>
        <a href='/'>
          <Logo src={logo} width='120' />
        </a>
      </div>
      <div>
        <CalendlyButton
          url='https://calendly.com/admin-o-lh'
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById('root')}
          text='Book a demo!'
        />
        <div>
          <a href='/login'>Log in</a>
        </div>
      </div>
    </UAHeader>
  );
};

export default Navigation;
