import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { gql, useApolloClient } from '@apollo/client';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  text-align: center;
`;
const Header = styled.div`
  font-weight: bold;
  text-align: center;
  color: #888;
  margin-bottom: 8px;
`;
const ScoreNumber = styled.div`
  color: #0047e3;
  font-size: 5rem;
  line-height: 5rem;
  font-weight: bold;
  text-align: center;
`;

const ScoreSummary = styled.div`
  color: #888;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;

const Scale = styled.div`
  margin: 0 auto;
  width: 160px;
  height: 16px;
  background-color: #eee;
  border-radius: 4px;
  text-align: center;
  color: #888;
  border: 1px solid #888;
`;

const MediumBar = styled.div`
  width: 50%;
  height: 100%;
  background-color: orange;
`;

const LowBar = styled.div`
  width: 30%;
  height: 100%;
  background-color: red;
`;

const ScoreDetail = styled.div`
  text-align: center;
`;

const ScoreInfo = styled.div`
  display: inline-block;

  border-radius: 4px;
  width: auto;

  margin-bottom: 16px;

`;

const GET_PROFILE = gql`
  query MyQuery($propertyId: uuid!) {
    properties(where: { id: { _eq: $propertyId } }) {
      id
      property_profiles {
        building_type
        construction_standard
        external_wall_material
        first_built
        foundations
        id
        interest
        number_of_bathrooms
        number_of_bedrooms
        number_of_car_spaces
        number_of_levels
        propertyId
        roof_material
        size_of_main_bedroom
        slope_of_land
        maintenance_level
      }
    }
  }
`;

const calculateScore = (profile) => {
  let score = 4.0;
  let confidence = 'Low';
  let building_age = 1;

  let building_type_score = 1;
  let building_foundations_score = 1;
  let building_external_walls_score = 1;
  let building_roof_score = 1;
  let building_construction_standard_score = 0.5;
  let building_site_slope_score = -1;
  let building_maintenance_level_score = 1;

  if (profile.first_built) {
    building_age = 2022 - parseFloat(profile.first_built);
    if (building_age < 1) building_age = 1;
  }

  const propertyTypeOptions = [
    { label: 'Free-standing home', score: 5 },
    { label: 'Townhouse', score: 4 },
    { label: 'Terrace house', score: 3 },
    { label: 'Semi-detached (e.g. duplex)', score: 2 },
    { label: 'Apartment', score: 1 },
  ];

  const propertyFoundationOptions = [
    { label: 'Concrete slab', score: 2 },
    { label: 'Stumps/foundations', score: 4 },
    { label: 'Deep piles', score: 5 },
    { label: 'Poles', score: 3 },
    { label: "I don't know", score: 1 },
  ];

  const propertyWallMaterialOptions = [
    { label: 'Brick veneer', score: 4 },
    { label: 'Double brick', score: 5 },
    { label: 'Weatherboard/wood', score: 2 },
    { label: 'Concrete', score: 5 },
    { label: 'Hardiplank/Hardiplex', score: 1 },
    { label: 'Vinyl cladding', score: 1 },
    { label: 'Fibro/abpestos', score: 0 },
    { label: 'Aluminium', score: 3 },
    { label: 'Hebel', score: 3 },
  ];
  const propertyRoofMaterialOptions = [
    { label: 'Aluminium', score: 2 },
    { label: 'Concrete', score: 4 },
    { label: 'Fibro/Asbestos Cement', score: 1 },
    { label: 'Iron (Corrugated)', score: 4 },
    { label: 'Slate', score: 3 },
    { label: 'Steel/Colorbond', score: 5 },
    { label: 'Tiles', score: 3 },
    { label: 'Timber', score: 2 },
  ];

  const propertyConstructionStandardOptions = [
    { label: 'Below average', score: 0.5 },
    { label: 'Average', score: 1.0 },
    { label: 'Above average', score: 1.5 },
  ];

  const propertySlopeOfLandOptions = [
    { label: 'Flat', score: 2 },
    { label: 'Gentle', score: 1 },
    { label: 'Moderate', score: 0 },
    { label: 'Severe', score: -1 },
  ];

  const propertyMaintenanceLevelOptions = [
    { label: 'Low', score: 0.5 },
    { label: 'Medium', score: 1.0 },
    { label: 'High', score: 1.5 },
  ];

  const building_type_weight = 0.5;
  const building_foundations_weight = 1.0;
  const building_external_walls_weight = 1.0;
  const building_roof_weight = 1.5;
  const building_site_slope_weight = 1.0;
  const building_construction_standard_weight = 1.0;
  const building_maintenance_level_weight = 1.0;

  if (
    profile.building_type &&
    profile.foundations &&
    profile.external_wall_material &&
    profile.construction_standard &&
    profile.roof_material &&
    profile.slope_of_land &&
    profile.maintenance_level
  ) {
    building_type_score = propertyTypeOptions.find(
      (o) => o.label === profile.building_type
    )?.score;
    if (building_type_score) building_type_score *= building_type_weight;
    console.log(profile.building_type, { building_type_score });

    building_foundations_score = propertyFoundationOptions.find(
      (o) => o.label === profile.foundations
    )?.score;
    if (building_foundations_score)
      building_foundations_score *= building_foundations_weight;
    console.log(profile.foundations, { building_foundations_score });

    building_external_walls_score = propertyWallMaterialOptions.find(
      (o) => o.label === profile.external_wall_material
    )?.score;
    if (building_external_walls_score)
      building_external_walls_score *= building_external_walls_weight;
    console.log(profile.external_wall_material, {
      building_external_walls_score,
    });

    building_roof_score = propertyRoofMaterialOptions.find(
      (o) => o.label === profile.roof_material
    )?.score;
    if (building_roof_score) building_roof_score *= building_roof_weight;
    console.log(profile.roof_material, { building_roof_score });

    building_construction_standard_score =
      propertyConstructionStandardOptions.find(
        (o) => o.label === profile.construction_standard
      )?.score;
    if (building_construction_standard_score)
      building_construction_standard_score *=
        building_construction_standard_weight;
    console.log(profile.roof_material, {
      building_construction_standard_score,
    });

    building_site_slope_score = propertySlopeOfLandOptions.find(
      (o) => o.label === profile.slope_of_land
    )?.score;
    if (building_site_slope_score)
      building_site_slope_score *= building_site_slope_weight;
    console.log(profile.slope_of_land, { building_site_slope_score });

    building_maintenance_level_score = propertyMaintenanceLevelOptions.find(
      (o) => o.label === profile.maintenance_level
    )?.score;
    if (building_maintenance_level_score)
      building_maintenance_level_score *= building_maintenance_level_weight;

    confidence = 'Medium';
  }

  let age_score = 2 * Math.log(143 - building_age);
  console.log(building_age, { age_score });

  score =
    (age_score +
      building_type_score +
      building_foundations_score +
      building_external_walls_score +
      building_roof_score +
      building_site_slope_score) *
    building_construction_standard_score *
    building_maintenance_level_score;

  const starting_score = 5.0;
  const upper_bound = 8.5;
  const max_raw_score = 58.87253417;
  const min_raw_score = 1.5;

  score =
    starting_score +
    ((score - min_raw_score) / (max_raw_score - min_raw_score)) *
      (upper_bound - starting_score);

  return [score, confidence];
};

const Score = ({ propertyId, currentUser }) => {
  const [profile, setProfile] = useState(null);
  const [score, setScore] = useState('-');
  const [confidence, setConfidence] = useState('Low');
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const handleAddDetail = () => {
    navigate('/property-profile/1');
  };

  const client = useApolloClient();

  const getProfile = async () => {
    const result = await client.query({
      query: GET_PROFILE,
      variables: { propertyId: propertyId, fetch: 'network-only' },
      fetchPolicy: 'network-only',
    });

    if (
      result?.data?.properties?.length &&
      result?.data?.properties[0].property_profiles?.length
    ) {
      setProfile(result.data.properties[0].property_profiles[0]);
    }
  };

  useEffect(() => {
    if (profile) {
      const [cs, cc] = calculateScore(profile);
      setScore(cs.toFixed(2));
      setConfidence(cc);
    }
  }, [profile]);

  useEffect(() => {
    getProfile();
  }, [propertyId]);

  const handleClose = () => {
    setShowModal(false);
  };

  const renderScoreSummary = (score) => {
    return (
      <strong>It's time to get your hands dirty and spruce things up!</strong>
    );
  };

  return (
    <>
      <Modal size='lg' show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>The Property Health Score</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Understanding Your Property Health Score:</strong> {score}
          </p>
          <p>
            Your property's Health Score, currently at {score}, is determined by
            our AI-powered analysis, comparing your property's structural
            integrity, construction quality, living conditions, operational
            expenses, and overall health with similar properties in our
            extensive database.
          </p>
          <p>
            <strong>Why a Higher Score Matters</strong>
          </p>
          <p>
            A robust Health Score indicates a well-maintained property, leading
            to reduced maintenance expenses, more affordable insurance premiums,
            and an increase in your property's market value.
          </p>
          <p>
            <strong>Improving Your Score</strong>
          </p>
          <p>
            You have the power to enhance your property's Health Score. Discover
            effective strategies here.
          </p>
          <p>
            <strong>Note:</strong> This score is based on the current data we
            have. Providing more detailed information about your property will
            allow for a more precise assessment.
          </p>
        </Modal.Body>
      </Modal>
      <Container>
        <Header>Your Property Health Score</Header>
        <ScoreNumber>{score}</ScoreNumber>
        <ScoreSummary>out of 10</ScoreSummary>
        <br />
        <Scale>
          {confidence === 'Medium' && <MediumBar>&nbsp;</MediumBar>}
          {confidence === 'Low' && <LowBar>&nbsp;</LowBar>}
        </Scale>
        {confidence} confidence
      </Container>
      <br />
      <ScoreDetail>
        <ScoreInfo>
          {/* {renderScoreSummary(score)} */}
          <Button variant='link' onClick={() => setShowModal(true)}>
            Learn more about this score
          </Button>
        </ScoreInfo>
        <br />

        {confidence === 'Low' && currentUser && (
          <Button onClick={handleAddDetail}>
            Complete your Property Profile for a better Health Score!
          </Button>
        )}
      </ScoreDetail>
    </>
  );
};

export default Score;
