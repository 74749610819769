import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import PropertySelector from "../Components/PropertySelector";
import FeatureGrid from "../Components/FeatureGrid";
import Score from "../Components/Score";

const PageContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  padding: 30px;
  border-radius: 12px;

`;

const Dashboard = () => {
  const navigate = useNavigate();
  const [selectedPropertyId, setSelectedPropertyId] = useState(null)

  const getCurrentUser = async () => {
    try {
    const user = await Auth.currentAuthenticatedUser();
    if (!user) {
      navigate("/login");
    }
    }catch(error){
      navigate("/login");
    }
  };

  useEffect(() => {
    getCurrentUser();
    const _selectedPropertyId = localStorage.getItem("selectedProperty");
    setSelectedPropertyId(_selectedPropertyId);
  }, []);

  return (
    <PageContainer>
      <PropertySelector onChange={e => setSelectedPropertyId(e.value)}/>
      <br/>
      <Row>
        <Col>
          {selectedPropertyId && <Score propertyId={selectedPropertyId} currentUser={true}/>}
        </Col>
      </Row>

      {/* <Row>
        <Col>
          <Button
            onClick={() => {
              navigate("/property-profile");
            }}
          >
            Property Profile
          </Button>
          <br />
          <br />
          <Button
            onClick={() => {
              navigate("/logbook");
            }}
          >
            Safely store a Property Document
          </Button>
        </Col>
      </Row> */}
<br/>
      <FeatureGrid />
    </PageContainer>
  );
};

export default Dashboard;
