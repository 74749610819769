import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const About = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Why a Property Logbook?</h2>
          <p>
            Selling, managing and maintaining a property is much easier when you
            have all the right information in one place: your Property Logbook.
          </p>
          <p>
            Property Logbook is the single best source of all property data - a
            mobile first solution that makes it easy to capture and access
            information anywhere, anytime.
          </p>
          <p>
            From storing paint codes, to locating warranty information, lodging
            plans for approvals, and advertising your property's health. All of
            this and more can be done much 100x better with Property Logbook.
          </p>

          <hr />
          <h3>The team</h3>
          <p>
            Our three founders, Rob, Tim and Dave have all experienced the pains
            that come with buying, managing and selling property, just like
            countless other Australians. Their complementary skills and
            experience as entrepreneurs and built environment professionals make
            them the perfect fit to solve those issues for everyone:
          </p>
          <p>
            Tim Rawling is an experienced structural engineer. He was also
            digital leader at Arup and a founder of VC-backed startup CalcTree.
          </p>
          <p>
            Robert Finkeldey is a serial entrepreneur and was the founder of
            Australia's first online marketplace for trades and services. He
            also ran a large market and business strategy group for a global
            tech consulting firm in Asia/Pac and Japan.{" "}
          </p>
          <p>
            Dave Towey is an experienced full-stack software developer with a
            keen eye for front-end development and user experience.{" "}
          </p>
          <hr />
          <p>
            Our vision is to provide access to the world's property information
            in one click, and change the way people and institutions interact
            with their homes for the better.
          </p>
          <p>
            Our mission is to organise property information and make it
            universally accessible and useful.
          </p>
          <p>
            Send us your feedback and ideas to{" "}
            <a href="mailto:founders@propertylogbook.com.au">
              founders@propertylogbook.com.au
            </a>{" "}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
