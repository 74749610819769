import React, { useState } from 'react';
import { Row, Button, Col, Form } from 'react-bootstrap';
import { useMutation, gql } from '@apollo/client';
import Select from 'react-select';
import ReactTagInput from '@pathofdev/react-tag-input';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const categoryOptions = [
  { label: 'Structural' },
  { label: 'Electrical' },
  { label: 'Plumbing' },
  { label: 'Garden' },
  { label: 'Walls' },
  { label: 'Roof' },
  { label: 'Windows' },
  { label: 'Security' },
  { label: 'Appliance' },
  { label: 'Contents' },
  { label: 'Other' },
];

const ADD_MAINTENANCE_LOG_DELETION = gql`
  mutation AddMaintenanceLogDeletion($propertyId: uuid!, $mlid: String!) {
    insert_maintenance_log_deletions_one(
      object: { propertyId: $propertyId, mlid: $mlid }
    ) {
      id
    }
  }
`;

const ADD_MAINTENANCE_LOG_CUSTOM_TASK = gql`
  mutation AddMaintenanceLogCustomTask(
    $propertyId: uuid!
    $description: String!
    $frequency: String!
  ) {
    insert_maintenance_log_custom_task_one(
      object: { propertyId: $propertyId, description: $description, frequency: $frequency }
    ) {
      id
    }
  }
`;

const TaskForm = ({ title, propertyId, mlId, onHide, triggerRefetch }) => {
  const [description, setDescription] = useState(title);
  const [frequency, setFrequency] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState([]);

  const [addMaintenanceLogDeletion] = useMutation(ADD_MAINTENANCE_LOG_DELETION);
  const [AddMaintenanceLogCustomTask] = useMutation(
    ADD_MAINTENANCE_LOG_CUSTOM_TASK
  );

  const handleDelete = async () => {
    const confirm = window.confirm(
      'Are you sure you want to delete this task for this property? Log history will be retained.'
    );
    if (!confirm) {
      return;
    }
    await addMaintenanceLogDeletion({
      variables: {
        propertyId: propertyId,
        mlid: mlId,
      },
    });
    triggerRefetch();
    onHide();
  };

  const handleSave = async () => {
    await AddMaintenanceLogCustomTask({
      variables: {
        propertyId: propertyId,
        description: description,
        frequency: frequency.value,
      },
    });
    triggerRefetch();
    onHide();
  };

  return (
    <>
      <Row>
        <Col>
          Task description{' '}
          <Form.Control
            type='text'
            placeholder='Enter task / description'
            value={description}
            onChange={(evt) => {
              setDescription(evt.target.value);
            }}
          />
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          Frequency{' '}
          <Select
            value={frequency}
            options={[
              { label: 'Weekly', value: 'weekly' },
              { label: 'Fortnightly', value: 'fortnightly' },
              { label: 'Monthly', value: 'monthly' },
              { label: 'Bimonthly', value: 'bimonthly' },
              { label: 'Quarterly', value: 'quarterly' },
              { label: 'Annually', value: 'annually' },
            ]}
            onChange={(val) => {
              setFrequency(val);
            }}
          />
        </Col>
        <Col>
          Starting from{' '}
          <DatePicker
            selected={startDate}
            onChange={(val) => setStartDate(val)}
            customInput={<Form.Control type='text' />}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Form.Label>Category</Form.Label>
          <Select
            placeholder='Select a category'
            options={categoryOptions}
            onChange={(val) => {}}
          />
          <br />
          <Form.Label>Tags</Form.Label>
          <ReactTagInput tags={[]} onChange={(newTags) => {}} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Form.Check
            type='switch'
            id='custom-switch'
            label='Remind me'
            checked={true}
          />
        </Col>
        <Col sm='auto'>
          <Button size='sm' variant='primary' onClick={handleSave}>
            Save
          </Button>{' '}
          {title && (
            <Button size='sm' variant='danger' onClick={handleDelete}>
              Delete this item
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default TaskForm;
