import React from "react";
import styled from "styled-components";
import { Star, StarFill } from "react-bootstrap-icons";

const StarsContainer = styled.div`
  color:#0047E3;
`;


const StarRating = () => (<StarsContainer><StarFill/><StarFill/><StarFill/><Star/><Star/></StarsContainer>);

export default StarRating;
