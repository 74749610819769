const propertyTypeOptions = [
  { label: "Free-standing home" },
  { label: "Townhouse" },
  { label: "Terrace house" },
  { label: "Semi-detached (e.g. duplex)" },
  { label: "Apartment" },
];

const propertyFoundationOptions = [
  { label: "Concrete slab" },
  { label: "Stumps/foundations" },
  { label: "Poles" },
];

const propertyWallMaterialOptions = [
  { label: "Brick veneer" },
  { label: "Double brick" },
  { label: "Weatherboard/wood" },
  { label: "Concrete" },
  { label: "Hardiplank/Hardiplex" },
  { label: "Vinyl cladding" },
  { label: "Fibro/abpestos" },
  { label: "Aluminium" },
  { label: "Hebel" },
];

const propertyRoofMaterialOptions = [
  { label: "Aluminium" },
  { label: "Concrete" },
  { label: "Fibro/Asbestos Cement" },
  { label: "Iron (Corrugated)" },
  { label: "Slate" },
  { label: "Steel/Colorbond" },
  { label: "Tiles" },
  { label: "Timber" },
];

const propertyConstructionStandardOptions = [
  { label: "Below average" },
  { label: "Average" },
  { label: "Above average" },
];

const propertyMaintenanceLevelOptions = [
  { label: "Low" },
  { label: "Medium" },
  { label: "High" },
];

const propertySlopeOfLandOptions = [
  { label: "Flat" },
  { label: "Gentle" },
  { label: "Moderate" },
  { label: "Severe" },
];

const propertyNumberOfLevelsOptions = [
  { label: "Single storey" },
  { label: "Double storey" },
  { label: "Three or more storeys" },
];

const propertyNumberOfBedroomsOptions = [
  { label: "1" },
  { label: "2" },
  { label: "3" },
  { label: "4" },
  { label: "5" },
  { label: "6" },
  { label: "7+" },
];

const propertySizeOfMainBedroomOptions = [
  { label: "Designed to fit double/queen bed" },
  { label: "Designed to fit king bed (>4.5x3m)" },
];

const propertyNumberOfBathroomsOptions = [
  { label: "1" },
  { label: "2" },
  { label: "3" },
  { label: "4" },
  { label: "5" },
  { label: "6+" },
];

const propertyNumberOfCoveredCarSpacesOptions = [
  { label: "1" },
  { label: "2" },
  { label: "3" },
  { label: "4+" },
];

export {
  propertyTypeOptions,
  propertyFoundationOptions,
  propertyWallMaterialOptions,
  propertyRoofMaterialOptions,
  propertyConstructionStandardOptions,
  propertyMaintenanceLevelOptions,
  propertySlopeOfLandOptions,
  propertyNumberOfLevelsOptions,
  propertyNumberOfBedroomsOptions,
  propertySizeOfMainBedroomOptions,
  propertyNumberOfBathroomsOptions,
  propertyNumberOfCoveredCarSpacesOptions,
};
