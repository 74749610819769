import React from 'react';
import Markdown from 'markdown-to-jsx';

const DataDeletion = () => (<Markdown>{`##PROPERTY LOGBOOK DATA DELETION
 
All user data may be deleted from our systems upon request to privacyofficer@propertylogbook.com.au. We undertake to perform the
deletion within one month (30 calendar days) and will send you a confirmation once the information has been
deleted. 
`}</Markdown>);

export default DataDeletion;