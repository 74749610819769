import { gql } from "@apollo/client";

const ADD_USER = gql`
mutation AddUserMutation(
  $sub: String! 
  $comment: String!
  ) {
    insert_user(objects: {sub: $sub, comment: $comment}){
    returning {
      sub
    }
  }
}`;

export default function addUser(client, sub, comment){
  return client.mutate({
    mutation: ADD_USER,
    variables: {
      sub: sub,
      comment: comment,
    },
  });
};


