import postRequest from '../Utils/postRequest';
import { HEALTH_SCORE_URL } from '../Constants/URL';

export default function fetchHealthScore(data) {
  return postRequest(HEALTH_SCORE_URL, data)
    .then((response) => response)
    .catch((error) => {
      // TODO handle API error
    });
}
