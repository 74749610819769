import React from 'react';
import Markdown from 'markdown-to-jsx';

const FeatureInsurance = () => (<Markdown>{`#Insurance

### Get better insurance with Property Logbook

Insurance companies make you pay more for the unknown risks in your property. The less they know, the higher your premium. Property Logbook is the ideal way to store all of your property files for safekeeping - and now also to communicate the information you want to share to get better home and contents insurance. 

And in the unfortunate event that there is a fire, you don’t have to worry about property files or contents receipts being destroyed. It’s all safely stored in the cloud.

**Insurance Quotes**

Property Logbook will help you get competitive and tailored insurance quotes. We are working with insurance companies on automated quotes at your request. Dynamic insurance...

Until that’s in place, drop us an email to (insurance@propertylogbook.com.au), and we will send you quotes obligation-free.

**Building information**
(Summary table based on wireframes or GIO quotation form)

If not already done so, add to your Property Logbook: floor plans, architectural plans, building inspection reports, renovation files and photos...

(link to Upload Logbook Data)

**Contents Documentation**
There is no easier way to save receipts and warranty documentation of your valuables. With every purchase you make, you can opt in to update your home and contents insurance automatically. Property Logbook makes it easy to define the current replacement value of your home contents, which is important as insurers warn that underestimating the combined value will mean that an individual item may not be able to be replaced for the amount it was insured under. While over-estimating will mean that you pay a higher premium for the same outcome at claim time. Issues you won’t face on Property Logbook. 

Also, finding purchase receipts is easy on Property Logbook, so making an insurance claim is not a worry anymore.

Plus, having home contents documents safely in the cloud, is not only an advantage with insurance companies but also for any warranty claims you might ever need to make.

link: Upload purchase receipts and warranty documentation

**Security**

The better your home security, the lower your insurance premium is. Home security is only about security hardware but also personal security practices.

*Security hardware includes doors, locks, alarm systems, lighting, motion detectors, and security camera systems. Personal security involves practices like ensuring doors are locked, alarms are activated, owning a dog, windows are closed, and extra keys are not hidden outside.*

While insurance companies do give discounts for particular security features, the real financial advantage comes from home security like CCTV acting as a deterrent. With no insurance claims to be filed, you can claim a No-Claim Bonus when it comes to renewal time. And then there is the peace of mind of not losing your belongings.

(recommended security installers)

**Local Climate Change Map**

Severe weather events and how your building is designed to respond to them will impact your insurance premium, or if insurance companies will insure your property, at all.

So when shopping for your next home or investment property, check what science says about how the local climate will change in the near future.

Property Logbook is working on models of what Climate Change means for your street and location. In the meantime, check out this government website:

[https://www.climatechangeinaustralia.gov.au/en/climate-projections/explore-data/map-explorer/](https://www.climatechangeinaustralia.gov.au/en/climate-projections/explore-data/map-explorer/)`}</Markdown>
);

export default FeatureInsurance;