import React, { useState } from "react";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { useParams } from "react-router-dom";

const ConfirmEmail = () => {
  const { id } = useParams();
  const localEmail = localStorage.getItem("CONFIRMATION_EMAIL");

  const [email, setEmail] = useState(localEmail);
  const [code, setCode] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();

    Auth.confirmSignUp(email, code)
      .then((result) => {
        if (result === "SUCCESS") {
          window.location.href = id ? `/login/${id}` : "/login";
        }
      })
      .catch((err) => {
        alert("Error verifying email");
      });
  };

  return (
    <Card>
      <Card.Header>Confirm Email</Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <Row>
            <Col>
              <div>Please check your email for a verification code</div>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="text"
                onChange={(evt) => setCode(evt.target.value)}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button type="submit">SUBMIT</Button>
        </Card.Footer>
      </Form>
    </Card>
  );
};

export default ConfirmEmail;
