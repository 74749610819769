import axios from "axios";

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

const postRequest = async (url = "", data = {}) =>
  await axios({
    url: url,
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    crossDomain: true,
    data,
  })
    .then((response) => response.data)
    .catch(handleErrors);

export default postRequest;
