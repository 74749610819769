import React, { useState } from 'react';
import styled from 'styled-components';
import {Row, Col} from 'react-bootstrap';
import {
  Journal,
  JournalPlus,
  Search,
  ClipboardCheck,
  ListCheck,
  Star,
} from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

const GridContainer = styled.div``;

const FirstRow = styled.div`
  display: flex;
  flex-direction: column;
  border: 3px solid #aaaaaa;
  border-radius: 16px;
  padding: 16px;

  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

const SummaryCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  background-color: #eee;
  margin: 1%;
  border-radius: 12px;
  padding: 20px 20px;
  font-weight: bold;
  color: #0047e3;
  display: none;
`;

const Icon = styled.div`
  display: flex;
  background-color: #0047e3;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 960px) {
    width: 56px;
    height: 56px;
    margin-bottom: 8px;
    margin-right: 0px;
  }
`;

const GridCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;

  color: #0047e3;
  font-weight: bold;
  margin: 1%;
  border-radius: 12px;
  cursor: pointer;

  @media (min-width: 960px) {
    height: 90px;
    flex-direction: column;
  }
`;

const content = {
  default: 'Hover over a feature to learn more about Property Logbook!',
  'Document storage':
    'Keep safe and easily retrieve plans, invoices and warranty documents at any time and from anywhere. And, no more fading receipts.',
  'Property health':
    'Live with confidence in a well maintained property, and advertise your Property Health Score to potential buyers and renters.',
  'Maintenance log':
    "Make it easy for tradesmen to provide the information your property needs for a reliable maintenance schedule using Property Logbook's easy upload forms.",
  Value:
    'A well maintained logbook drives up property value. We show you how to stand out from the crowd and get return on investment.',

  Accessibility:
    'Accessibility concerns around ease and convenience to enter a property limit market demand and decrease value. Follow our advice and score points with future buyers.',
  'Smart home':
    'Tech-enabled homes command higher market prices and are easier to sell as a result. But not all smart home tech have a positive ROI. Follow our advice and score points with future buyers. (also: access your smart home tech via Property Logbook).',
  'Healthy living':
    'Record air, light, and noise pollution levels to identify mitigation strategies that make your home a healthier place to live in, and will attract future buyers who are sensitive to pollution and willing to pay a premium for peace of mind.',
  Security:
    'Learn which security solutions can drive down your insurance premiums and increase property value. Security cam snapshots can be uploaded onto your property logbook for safe storage and insurance claims.',
  'Energy rating':
    "Establish, improve and advertise your property's energy rating using Property Logbook's guided process.",
  'Noise levels':
    "Indoors and outdoors noise levels can be established and advertised using Property Logbook's built-in algorithm.",
};
const FeatureGrid = () => {
  const [summary, setSummary] = useState(
    'Hover over a feature to learn more about Property Logbook'
  );

  const navigate = useNavigate();

  return (
    <GridContainer>
      <GridRow>
        <SummaryCell>{summary}</SummaryCell>
      </GridRow>
      <FirstRow>
        <GridCell
          onMouseEnter={() => setSummary(content['Value'])}
          onMouseLeave={() => setSummary(content['default'])}
          onClick={() => navigate('/logbook')}
        >
          <Icon>
            <JournalPlus size={24} />
          </Icon>
          <div>Add Logbook Entry</div>
        </GridCell>
        <GridCell
          onMouseEnter={() => setSummary(content['Accessibility'])}
          onMouseLeave={() => setSummary(content['default'])}
          onClick={() => navigate('/logbook')}
        >
          <Icon>
            <Search size={24} />
          </Icon>
          <div>Search Logbook</div>
        </GridCell>
        <GridCell
          onMouseEnter={() => setSummary(content['Value'])}
          onMouseLeave={() => setSummary(content['default'])}
          onClick={() => navigate('/property-profile')}
        >
          <Icon>
            <ClipboardCheck size={24} />
          </Icon>
          <div>Property Health</div>
        </GridCell>
        <GridCell
          onMouseEnter={() => setSummary(content['Value'])}
          onMouseLeave={() => setSummary(content['default'])}
          onClick={() => navigate('/maintenance-log')}
        >
          <Icon>
            <ListCheck size={24} />
          </Icon>
          <div>Maintenance Log</div>
        </GridCell>
        <GridCell
          onMouseEnter={() => setSummary(content['Value'])}
          onMouseLeave={() => setSummary(content['default'])}
          onClick={() => navigate('/rewards')}
        >
          <Icon>
            <Star size={24} />
          </Icon>
          <div>Rewards</div>
        </GridCell>
      </FirstRow>
      <br/>
      <Row>
        <Col>
          <ul>
            {' '}
            <li>
              <a href='#'>Property benefits</a>
            </li>
            <li>
              <a href='#'>Sustainability</a>
            </li>
          </ul>
        </Col>
        <Col>
          <ul>
            <li>
              <a href='#'>Your directory</a>
            </li>
            <li>
              <a href='#'>Insurance</a>
            </li>
          </ul>
        </Col>
        <Col>
          <ul>
            <li>
              <a href='#'>Property inspection</a>
            </li>
            <li>
              <a href='#'>Finance</a>
            </li>
          </ul>
        </Col>
        <Col>
          <ul>
            {' '}
            <li>
              <a href='#'>Neighbourhood</a>
            </li>
            <li>
              <a href='#'>Public property data</a>
            </li>
          </ul>
        </Col>
      </Row>

      {/* <GridRow>
        <GridCell
          onMouseEnter={() => setSummary(content["Maintenance log"])}
          onMouseLeave={() => setSummary(content["default"])}
        >
          <Icon />
          <div>Property Benefits</div>
        </GridCell>
        <GridCell
          onMouseEnter={() => setSummary(content["Accessibility"])}
          onMouseLeave={() => setSummary(content["default"])}
        >
          <Icon />
          Sustainability
        </GridCell>

        <GridCell
          onMouseEnter={() => setSummary(content["Smart home"])}
          onMouseLeave={() => setSummary(content["default"])}
        >
          <Icon />
          Your Directory
        </GridCell>
      </GridRow>
      <GridRow>
        <GridCell
          onMouseEnter={() => setSummary(content["Healthy living"])}
          onMouseLeave={() => setSummary(content["default"])}
          onClick={() => navigate("/feature/insurance")}
        >
          <Icon />
          Insurance
        </GridCell>
        <GridCell
          onMouseEnter={() => setSummary(content["Property health"])}
          onMouseLeave={() => setSummary(content["default"])}
          onClick={() => navigate("/feature/property-health")}
        >
          <Icon />
          Property Inspection
        </GridCell>
        <GridCell
          onMouseEnter={() => setSummary(content["Security"])}
          onMouseLeave={() => setSummary(content["default"])}
        >
          <Icon />
          Finance
        </GridCell>
      </GridRow>

      <GridRow>
        <GridCell
          onMouseEnter={() => setSummary(content["Security"])}
          onMouseLeave={() => setSummary(content["default"])}
        >
          <Icon />
          Neighbourhood
        </GridCell>
        <GridCell
          onMouseEnter={() => setSummary(content["Security"])}
          onMouseLeave={() => setSummary(content["default"])}
        >
          <Icon />
          Public Property Data
        </GridCell>
        <GridCell>&nbsp;</GridCell>
      </GridRow> */}
    </GridContainer>
  );
};

export default FeatureGrid;
