import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import addUser from "../Services/addUser";


const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { id } = useParams();
  const client = useApolloClient();

  const handleSubmit = (evt) => {
    evt.preventDefault();

    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      },
    })
      .then(async (user) => {
        localStorage.setItem(
          'CONFIRMATION_EMAIL',
          email
        );

        await addUser(client, user.userSub, email);
        window.location.href = id ? `/confirm-email/${id}`: '/confirm-email';
      })
      .catch((err) => {
        alert("Sign-up error!");
        console.log({ err });
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* <Row>
            <Col>
              <Form.Group controlId="formAddress">
                <Form.Label>Property address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Type address ..."
                  onChange={(evt) => setPropertyAddress(evt.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>What is your interest in the property?</Form.Label>
                <Form.Select
                  aria-label="What is your interest in the property?"
                  onChange={(evt) => setPropertyInterest(evt.target.value)}
                >
                  <option>Select</option>
                  <option value="own">I own and live in this property</option>
                  <option>I rent this property</option>
                  <option>I own but do not live in this property</option>
                  <option>I manage this property</option>
                  <option>I want to buy this property</option>
                  <option>I viewed this property</option>
                  <option>I am a neighbour</option>
                  <option>I service this property</option>
                  <option>Other=</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <br /> */}
      <Row>
        <Col>
          <h3>Sign-up to Property Logbook</h3>
          <br />
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="text"
              password="true"
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Card>
            <Card.Body>(Insert social login options)</Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Button type="submit">Submit</Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          Already have an account?{" "}
          <a href={id ? `/login/${id}` : "/login"}>Login here</a>
        </Col>
      </Row>
    </Form>
  );
};

export default SignUp;
