import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

const Home = () => {
  const navigate = useNavigate();

  const getCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        navigate("/dashboard");
      } else {
        navigate("/new-property");
      }
    } catch (error) {
      navigate("/new-property");
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return null;
};

export default Home;
