import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import Score from '../Components/Score';
import ClaimProperty from '../Components/ClaimProperty';
import { useMutation, gql } from '@apollo/client';
import claimProperty from '../Services/claimProperty';

const PropertyClaimForm = styled.div`
  margin: 0 auto;
  border-radius: 4px;
  width: 100%;

  max-width: 420px;
  color: #888;
`;

const ScoreCard = styled.div`
  margin-top: 100px;
  width: 480px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  border-radius: 12px;
  padding: 15px 10px 10px 10px;
`;

const ClaimContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const UPDATE_PROPERTY_PROFILE = gql`
  mutation UpdatePropertyMutation(
    $formattedAddress: String!
    $buildingType: String!
    $foundations: String!
    $externalWallMaterial: String!
    $roofMaterial: String!
    $constructionStandard: String!
    $slopeOfLand: String!
  ) {
    update_property_profile(
      where: { property: { formatted_address: { _eq: $formattedAddress } } }
      _set: {
        building_type: $buildingType
        foundations: $foundations
        external_wall_material: $externalWallMaterial
        roof_material: $roofMaterial
        construction_standard: $constructionStandard
        slope_of_land: $slopeOfLand
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_PROPERTY_PROFILE_TWO = gql`
  mutation UpdatePropertyMutationTwo(
    $formattedAddress: String!
    $numberOfLevels: String!
    $numberOfBedrooms: String!
    $sizeOfMainBedroom: String!
    $numberOfBathrooms: String!
    $numberOfCarSpaces: String!
  ) {
    update_property_profile(
      where: { property: { formatted_address: { _eq: $formattedAddress } } }
      _set: {
        number_of_levels: $numberOfLevels
        number_of_bedrooms: $numberOfBedrooms
        size_of_main_bedroom: $sizeOfMainBedroom
        number_of_bathrooms: $numberOfBathrooms
        number_of_car_spaces: $numberOfCarSpaces
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const HealthScore = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [showClaimForm, setShowClaimForm] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const [score, setScore] = useState('7.0');
  const [confidence, setConfidence] = useState('Low');
  const { step } = useParams();
  const client = useApolloClient();

  const [updatePropertyProfile, updateResponse] = useMutation(
    UPDATE_PROPERTY_PROFILE
  );
  const [updatePropertyProfileTwo, updateResponseTwo] = useMutation(
    UPDATE_PROPERTY_PROFILE_TWO
  );

  const getCurrentUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    console.log({ user });
    setCurrentUser(user);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  // useEffect(() => {
  //   fetchHealthScore(scoreInput)
  //     .then((response) => {
  //       console.log(response);
  //       setScore(response.result.toFixed(1));
  //       setConfidence(response.confidence.toFixed(1));
  //     })
  //     .catch((err) => {
  //       console.error({ err });
  //     });
  // }, []);

  const getFormOneData = () => ({
    buildingType: '',
    foundations: '',
    externalWallMaterial: '',
    roofMaterial: '',
    constructionStandard: '',
    slopeOfLand: '',
  });

  const getFormTwoData = () => ({
    numberOfLevels: '',
    numberOfBedrooms: '',
    sizeOfMainBedroom: '',
    numberOfBathrooms: '',
    numberOfCarSpaces: '',
  });

  const submitFormStepOne = (formData) => {
    //   console.log(formData);
    //   console.log(scoreInput.address)
    //   updatePropertyProfile({
    //     variables: {
    //       formattedAddress: scoreInput.address,
    //       buildingType: formData.buildingType,
    //       foundations: formData.foundations,
    //       externalWallMaterial: formData.externalWallMaterial,
    //       roofMaterial: formData.roofMaterial,
    //       constructionStandard: formData.constructionStandard,
    //       slopeOfLand: formData.slopeOfLand,
    //     }
    //   })
    //   navigate("/owner");
  };

  const submitFormStepTwo = (formData) => {
    console.log(formData);
    //   updatePropertyProfileTwo({
    //     variables: {
    //       formattedAddress: scoreInput.address,
    //       numberOfLevels: formData.numberOfLevels,
    //       numberOfBedrooms: formData.numberOfBedrooms,
    //       sizeOfMainBedroom: formData.sizeOfMainBedroom,
    //       numberOfBathrooms: formData.numberOfBathrooms,
    //       numberOfCarSpaces: formData.numberOfCarSpaces,
    //     }
    //   })
    //   navigate("/owner");
  };

  const claim = async () => {
    if (id) {
      await claimProperty(client, currentUser.username, id);
      localStorage.setItem('selectedProperty', id);
    }

    navigate('/dashboard');
  };

  return (
    <>
      {!showClaimForm && (
        <ScoreCard>
          <Row>
            <Col>
              <Score propertyId={id} currentUser={currentUser} />
              {!currentUser && (
                <ClaimContainer>
                  <Button onClick={() => setShowClaimForm(true)}>
                    Claim this property!
                  </Button>
                </ClaimContainer>
              )}
            </Col>
          </Row>
        </ScoreCard>
      )}

      {showClaimForm && (
        <ScoreCard>
        <Row>
          <Col>
            {!currentUser && (
              <PropertyClaimForm>
                <ClaimProperty id={id} />
              </PropertyClaimForm>
            )}
            {currentUser && (
              <Button onClick={claim}>Claim this property</Button>
            )}
          </Col>
        </Row>
        </ScoreCard>
      )}

      <br />
      <ScoreCard>
      <Row>
        <Col>
          <h5>Benefits of your Property Health Score</h5>
          <br />
          <ul>
            <li>
              <strong>Unlock Financial Advantages:</strong> Gain access to
              exclusive, more favourable terms from insurers and banks.
            </li>
            <li>
              <strong>Efficient Tax Management:</strong> Easily generate
              comprehensive tax depreciation schedules, simplifying your
              financial planning and potentially reducing your tax burden.
            </li>
            <li>
              <strong>Secure, Cloud-Based Document Storage:</strong>{' '}
              Effortlessly store and protect important documents like plans,
              contracts, and receipts in a secure cloud environment.
            </li>
            <li>
              <strong>Efficient Document Retrieval:</strong> Quickly find
              exactly what you need, when you need it — from warranties to
              contracts — with our advanced search tools.
            </li>
            <li>
              <strong>Enhance Your Property's Marketability:</strong> A
              well-documented Property Logbook significantly increases buyer
              interest, leading to better sales results.
            </li>
            <li>
              <strong>Streamlined Maintenance Planning:</strong> Our service
              schedule feature allows for easy planning and budgeting of
              property maintenance tasks.
            </li>
            <li>
              <strong>Showcase Your Property's Health Score:</strong> Advertise
              your property's Health Score to potential buyers, demonstrating
              its excellent condition and value
            </li>
          </ul>
        </Col>
      </Row>
    </ScoreCard>
    </>
  );
};

export default HealthScore;
