import { gql } from "@apollo/client";

const ADD_USER_PROPERTY = gql`
mutation AddUserPropertyMutation(
  $userId: String! 
  $propertyId: uuid!
  ) {
    insert_user_property(objects: {userId: $userId, propertyId: $propertyId}){
    returning {
      id
    }
  }
}`;

export default function claimProperty(client, sub, propertyId){
  return client.mutate({
    mutation: ADD_USER_PROPERTY,
    variables: {
      userId: sub,
      propertyId: propertyId,
    },
  });
};