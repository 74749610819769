import React from 'react';


const NewProperty = () => {

  return (
    <>
    <br />
    <div style={{ textAlign: "center" }}>
      <h3>Add a new property</h3>
    </div>
    <br />
    
    </>
  );
}

export default NewProperty;