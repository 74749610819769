import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useGoogleSheets from 'use-google-sheets';
import { useApolloClient, gql } from '@apollo/client';
import { Accordion, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import {
  CalendarCheckFill,
  CalendarFill,
  PencilFill,
} from 'react-bootstrap-icons';
import Select, { useStateManager } from 'react-select';
import MaintenanceLogItem from '../Components/MaintenanceLogItem';
import PropertySelector from '../Components/PropertySelector';
import TaskForm from '../Components/TaskForm';

const IconWrapper = styled.span`
  display: inline-block;
  margin-right: 8px;

  color: ${(props) => (props.done ? '#2caf01' : 'gray')};
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const GET_LOG_ITEMS = gql`
  query maitenanceLogItems($propertyId: uuid!) {
    maintenance_log(where: { propertyId: { _eq: $propertyId } }) {
      id
      created_at
      done_by
      frequency
      notes
      propertyId
      title
      tags
      reminder
      updated_at
      done_date
    }
  }
`;

const GET_LOG_DELETIONS = gql`
  query maitenanceLogDeletions($propertyId: uuid!) {
    maintenance_log_deletions(where: { propertyId: { _eq: $propertyId } }) {
      id
      mlid
    }
  }
`;

const GET_CUSTOM_LOG_ITEMS = gql`
  query maitenanceLogCustomItems($propertyId: uuid!) {
    maintenance_log_custom_task(where: { propertyId: { _eq: $propertyId } }) {
      id
      description
      frequency
      propertyId
    }
  }
`;

const ItemDetail = () => {
  const addDeletion = () => {};

  return (
    <>
      <Row>
        <Button size='sm'>Done!</Button>
      </Row>
      <hr />
      <Row>
        <Col>
          Frequency{' '}
          <Select
            setValue='Monthly'
            options={[
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Fortnightly', value: 'Fortnightly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Bimonthly', value: 'Bimonthly' },
              { label: 'Quarterly', value: 'Quarterly' },
              { label: 'Annually', value: 'Annually' },
            ]}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Form.Check type='switch' id='custom-switch' label='Remind me' />
        </Col>
        <Col sm='auto'>
          <Button size='sm'>
            <PencilFill /> Edit
          </Button>{' '}
          <Button onClick={addDeletion()} size='sm' variant='danger'>
            Delete
          </Button>
        </Col>
      </Row>
    </>
  );
};


const MaintenanceLog = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [logItems, setLogItems] = useState([]);
  const [logDeletions, setLogDeletions] = useState([]);
  const [customLogItems, setCustomLogItems] = useState([]);
  const [itemsLoaded, setItemsLoaded] = useState(false);
  const [deletionsLoaded, setDeletionsLoaded] = useState(false);
  const [customItemsLoaded, setCustomItemsLoaded] = useState(false);
  const [refetchId, setRefetchId] = useState(0);
  const [isAdding, setIsAdding] = useState(false);

  const client = useApolloClient();

  const getLogItems = async () => {
    if (propertyId) {
      try {
        const result = await client.query({
          query: GET_LOG_ITEMS,
          variables: { propertyId: propertyId },
          fetchPolicy: 'network-only',
        });
        setLogItems(result.data.maintenance_log);
        setItemsLoaded(true);
      } catch (e) {
        console.log(e);
      }
      try {
        const result = await client.query({
          query: GET_CUSTOM_LOG_ITEMS,
          variables: { propertyId: propertyId },
          fetchPolicy: 'network-only',
        });
        console.log(result);
        setCustomLogItems(result.data.maintenance_log_custom_task);
        setCustomItemsLoaded(true);
      } catch (e) {
        console.log(e);
      }
      try {
        const result = await client.query({
          query: GET_LOG_DELETIONS,
          variables: { propertyId: propertyId },
          fetchPolicy: 'network-only',
        });
        setLogDeletions(result.data.maintenance_log_deletions);
        setDeletionsLoaded(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const incrementRefetchId = () => {
    setRefetchId(refetchId + 1);
  };

  const handleChangeProperty = (property) => {
    setPropertyId(property.value);
  };

  useEffect(() => {
    getLogItems();
  }, [propertyId, refetchId]);

  const { data, loading, error } = useGoogleSheets({
    apiKey: 'AIzaSyCrjguluCn9Lqy_XDgc_x81gBXuOXTZ6sw',
    sheetId: '1yyR-ZNC2Pc79OF4LBe2f9zMCisE4aYOJ2e6UkgQORFU',
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  // if (!deletionsLoaded) {
  //   return <div>Loading...</div>;
  // }

  if (error) {
    return <div>Error!</div>;
  }

  const templateItems = data[0].data;
  const allTemplateItems = [...templateItems, ...customLogItems];
  console.log(customLogItems)

  const culledTemplateItems = allTemplateItems.filter((item) => {
    const deletionIndex = logDeletions.findIndex(
      (logItem) => logItem.mlid === item.Id
    );
    return deletionIndex === -1;
  });

  const displayItems = culledTemplateItems.map((item) => {
    const foundItem = logItems.find(
      (logItem) => logItem.title === item.description
    );
    if (foundItem) {
      if (foundItem.done_date === null){
        let initialDueDate = new Date();
        if(item.frequency === 'annually') {
          initialDueDate = new Date(item.start_date);
        }
        return { ...item, alreadyDone: false, dueDate: initialDueDate };
      }

      let dueDate = new Date(foundItem.done_date);
      console.log(foundItem.title,dueDate);

      if (item.frequency === 'weekly') dueDate.setDate(dueDate.getDate() + 7);
      if (item.frequency === 'fortnightly')
        dueDate.setDate(dueDate.getDate() + 14);
      if (item.frequency === 'monthly')
        dueDate.setMonth(dueDate.getMonth() + 1);
      if (item.frequency === 'bimonthly')
        dueDate.setMonth(dueDate.getMonth() + 2);
      if (item.frequency === 'quarterly')
        dueDate.setMonth(dueDate.getMonth() + 3);
      if (item.frequency === 'annually')
        dueDate.setFullYear(dueDate.getFullYear() + 1);

      return { ...item, alreadyDone: false, dueDate: dueDate };
    }


    let initialDueDate = new Date();
        if(item.frequency === 'annually') {
          initialDueDate = new Date(item.start_date);
          console.log(initialDueDate);
        }
        return { ...item, alreadyDone: false, dueDate: initialDueDate };
  });

  const sortedDisplayItems = displayItems.sort((a, b) => {
    return a.dueDate - b.dueDate;
  });
  // const categories = [
  //   ...new Set(sheetData.map((val) => ({ label: val.Category }))),
  // ];
  // const cats = [...new Set(sheetData.map((val) => val.Category))];

  return (
    <>
      <PropertySelector onChange={handleChangeProperty} />
      <hr />
      {/* <Select options={[{ value: "All categories", label: "All categories" }, ...categories]}/><br/> */}
      {!isEditing && (
        <div>
          <Button size='sm' onClick={() => setIsEditing(true)}>
            Edit item list
          </Button>
        </div>
      )}
      {isEditing && (
        <div>
          <Button size='sm' onClick={() => setIsAdding(true)}>
            Add new item
          </Button>{' '}
          <Button size='sm' onClick={() => setIsEditing(false)}>
            Finish editing
          </Button>
        </div>
      )}
      <br />

      <div>
        {sortedDisplayItems.map((item, i) => {
          // if (item.Category === category) {
          return (
            <MaintenanceLogItem
              eventKey={i}
              key={`${i}${item.description}`}
              title={item.description}
              alreadyDone={item.alreadyDone}
              propertyId={propertyId}
              dueDate={item.dueDate}
              isEditing={isEditing}
              mlId={item.Id}
              triggerRefetch={incrementRefetchId}
            />
          );
        })}
        <Modal
          show={isAdding}
          onHide={() => {
            setIsAdding(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add task</Modal.Title>
          </Modal.Header>
          <Modal.Body><TaskForm propertyId={propertyId}  triggerRefetch={incrementRefetchId} /></Modal.Body>
        </Modal>
      </div>
    </>
  );
};

/*
Replace/ clean HVAC filters Clean gutters and downspouts Inspect roof and
      chimney for damage Reseal the deck, fence, outside woodwork Inspect
      driveway and other exterior concrete pathways Inspect sprinkler heads,
      test irrigation system Inspect and clean dryer vent Clean bathroom vent
      fans Test home alarm Service heating system Schedule a chimney sweep Seal
      cracks on windows and doors Turn off outdoor water Winterize sprinkler
      system Rake leaves Clean gutters and downspouts*/

export default MaintenanceLog;
