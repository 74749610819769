import React, { useState } from 'react';
import styled from 'styled-components';
import { Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import LogRocket from 'logrocket';

import Home from './Pages/Home';
import About from './Pages/About';
import ConfirmEmail from './Pages/ConfirmEmail';
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
import Landing from './Pages/Landing';
import Navigation from './Components/Navigation';
import Footer from './Components/Footer';
import Owner from './Pages/Owner';
import Renter from './Pages/Renter';
import LogBook from './Pages/LogBook';
import NewProperty from './Pages/NewProperty';
import PropertyProfile from './Pages/PropertyProfile';
import MaintenanceLog from './Pages/MaintenanceLog';
import Rewards from './Pages/Rewards';
import HealthScore from './Pages/HealthScore';

import FeatureInsurance from './Pages/features/Insurance';
import Terms from './Pages/legals/Terms';
import Privacy from './Pages/legals/Privacy';
import DataDeletion from './Pages/legals/DataDeletion';

import GoogleAuth from './Pages/GoogleAuth';
import FbAuth from './Pages/FbAuth';

import bg from './bg.png';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from '@apollo/client';
import Dashboard from './Pages/Dashboard';
import PropertyHealth from './Pages/features/PropertyHealth';

const AUTH_USER_TOKEN_KEY = 'PB_TOKEN';

LogRocket.init('gsjfbf/property-logbook');

const tokenFetch = (uri, options) => {
  // return Auth.currentSession()
  //   .then((session) => {
  //     console.log({session})
  //     const token = session.idToken.jwtToken;
  //     options.headers.authorization = token ? `Bearer ${token}` : "";
  //     return fetch(uri, options);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     return fetch(uri, options);
  //   });
  const token = sessionStorage.getItem(AUTH_USER_TOKEN_KEY);
  console.log({ token });
  if (token) options.headers.authorization = token ? `Bearer ${token}` : '';
  return fetch(uri, options);
};

const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.propertylogbook.com.au/v1/graphql',
    fetch: tokenFetch,
  }),
  cache: new InMemoryCache(),
});

const MainContainer = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: white;
  background-image: url(${bg});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 65%;
  background-attachment: fixed;
`;

const PageContainer = styled.div`
  /* border-top: 3px solid #aaaaaa;
  padding-top: 30px; */
`;

function App() {
  const [scoreInput, setScoreInput] = useState({});
  return (
    <MainContainer>
      <Navigation />
      <PageContainer>
        <Container>
          <ApolloProvider client={client}>
            <Routes>
              <Route path='/' element={<Home />} />

              <Route path='/new-property' element={<Landing />} />
              <Route path='/health-score' element={<HealthScore />}>
                <Route path=':id' element={<HealthScore />} />
              </Route>
              <Route path='signup' element={<SignUp />}>
                <Route path=':id' element={<SignUp />} />
              </Route>
              <Route path='confirm-email' element={<ConfirmEmail />}>
                <Route path=':id' element={<ConfirmEmail />} />
              </Route>

              <Route path='login' element={<Login />}>
                <Route path=':id' element={<Login />} />
              </Route>
              <Route path='logbook' element={<LogBook />} />

              <Route path='dashboard' element={<Dashboard />} />

              <Route path='property-profile' element={<PropertyProfile />}>
                <Route path=':step' element={<PropertyProfile />} />
              </Route>

              <Route path='about' element={<About />} />
              <Route path='new-property' element={<NewProperty />} />
              <Route path='/owner' element={<Owner scoreInput={scoreInput} />}>
                <Route
                  path=':step'
                  element={<Owner scoreInput={scoreInput} />}
                />
              </Route>
              <Route
                path='/renter'
                element={<Renter scoreInput={scoreInput} />}
              >
                <Route
                  path=':step'
                  element={<Renter scoreInput={scoreInput} />}
                />
              </Route>

              <Route path='/feature/insurance' element={<FeatureInsurance />} />
              <Route
                path='/feature/property-health'
                element={<PropertyHealth />}
              />
              <Route path='/maintenance-log' element={<MaintenanceLog />} />
              <Route path='/rewards' element={<Rewards />} />

              <Route path='/googleAuth' element={<GoogleAuth />}>
                <Route path=':expiry/:propertyId' element={<GoogleAuth />} />
              </Route>
              <Route path='/fbAuth' element={<FbAuth />}>
                <Route path=':expiry/:propertyId' element={<FbAuth />} />
              </Route>

              <Route path='/terms' element={<Terms />} />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='/data-deletion' element={<DataDeletion />} />
            </Routes>
          </ApolloProvider>
        </Container>
      </PageContainer>
      <br />
      <br />
      <br />
      <Footer />
    </MainContainer>
  );
}

export default App;
