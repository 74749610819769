import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { useMutation, gql } from '@apollo/client';
import fetchHealthScore from '../Services/fetchHealthScore';
import PropertySelector from '../Components/PropertySelector';
import Select from 'react-select';
import {
  propertyTypeOptions,
  propertyFoundationOptions,
  propertyWallMaterialOptions,
  propertyRoofMaterialOptions,
  propertyConstructionStandardOptions,
  propertySlopeOfLandOptions,
  propertyNumberOfLevelsOptions,
  propertyNumberOfBedroomsOptions,
  propertySizeOfMainBedroomOptions,
  propertyNumberOfBathroomsOptions,
  propertyNumberOfCoveredCarSpacesOptions,
} from '../Utils/formOptions';

const Score = styled.div`
  color: #0047e3;
  font-size: 5rem;
  line-height: 5rem;
  font-weight: bold;
  text-align: center;
`;

const ScoreSummary = styled.div`
  color: #888;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;

const Scale = styled.div`
  width: 160px;
  height: 24px;
  background-color: #ccc;
  margin: 0 auto;
  border-radius: 4px;
  text-align: center;
  color: #888;
`;

const PropertyClaimForm = styled.div`
  background-color: #ccc;
  margin: 0 auto;
  border-radius: 4px;
  width: 100%;
  height: 120px;
  max-width: 420px;
  color: #888;
`;

const UPDATE_PROPERTY_PROFILE = gql`
  mutation UpdatePropertyMutation(
    $formattedAddress: String!
    $buildingType: String!
    $foundations: String!
    $externalWallMaterial: String!
    $roofMaterial: String!
    $constructionStandard: String!
    $slopeOfLand: String!
  ) {
    update_property_profile(
      where: { property: { formatted_address: { _eq: $formattedAddress } } }
      _set: {
        building_type: $buildingType
        foundations: $foundations
        external_wall_material: $externalWallMaterial
        roof_material: $roofMaterial
        construction_standard: $constructionStandard
        slope_of_land: $slopeOfLand
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_PROPERTY_PROFILE_TWO = gql`
  mutation UpdatePropertyMutationTwo(
    $formattedAddress: String!
    $numberOfLevels: String!
    $numberOfBedrooms: String!
    $sizeOfMainBedroom: String!
    $numberOfBathrooms: String!
    $numberOfCarSpaces: String!
  ) {
    update_property_profile(
      where: { property: { formatted_address: { _eq: $formattedAddress } } }
      _set: {
        number_of_levels: $numberOfLevels
        number_of_bedrooms: $numberOfBedrooms
        size_of_main_bedroom: $sizeOfMainBedroom
        number_of_bathrooms: $numberOfBathrooms
        number_of_car_spaces: $numberOfCarSpaces
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const Owner = ({ scoreInput }) => {
  const navigate = useNavigate();
  const [score, setScore] = useState('');
  const [confidence, setConfidence] = useState('');
  const { step } = useParams();

  const [updatePropertyProfile, updateResponse] = useMutation(
    UPDATE_PROPERTY_PROFILE
  );
  const [updatePropertyProfileTwo, updateResponseTwo] = useMutation(
    UPDATE_PROPERTY_PROFILE_TWO
  );

  useEffect(() => {
    fetchHealthScore(scoreInput)
      .then((response) => {
        console.log(response);
        setScore(response.result.toFixed(1));
        setConfidence(response.confidence.toFixed(1));
      })
      .catch((err) => {
        console.error({ err });
      });
  }, []);

  const getFormOneData = () => ({
    buildingType: '',
    foundations: '',
    externalWallMaterial: '',
    roofMaterial: '',
    constructionStandard: '',
    slopeOfLand: '',
  });

  const getFormTwoData = () => ({
    numberOfLevels: '',
    numberOfBedrooms: '',
    sizeOfMainBedroom: '',
    numberOfBathrooms: '',
    numberOfCarSpaces: '',
  });

  const submitFormStepOne = (formData) => {
    console.log(formData);
    console.log(scoreInput.address);
    updatePropertyProfile({
      variables: {
        formattedAddress: scoreInput.address,
        buildingType: formData.buildingType,
        foundations: formData.foundations,
        externalWallMaterial: formData.externalWallMaterial,
        roofMaterial: formData.roofMaterial,
        constructionStandard: formData.constructionStandard,
        slopeOfLand: formData.slopeOfLand,
      },
    });
    navigate('/owner');
  };

  const submitFormStepTwo = (formData) => {
    console.log(formData);
    updatePropertyProfileTwo({
      variables: {
        formattedAddress: scoreInput.address,
        numberOfLevels: formData.numberOfLevels,
        numberOfBedrooms: formData.numberOfBedrooms,
        sizeOfMainBedroom: formData.sizeOfMainBedroom,
        numberOfBathrooms: formData.numberOfBathrooms,
        numberOfCarSpaces: formData.numberOfCarSpaces,
      },
    });
    navigate('/owner');
  };

  if (step === '1') {
    return (
      <>
        <Row>
          <Col>
            <p>
              Success! you have registered your Property Logbook for: <br />
              <strong>{scoreInput.address}</strong>
            </p>
            <Formik
              enableReinitialize={true}
              onSubmit={submitFormStepOne}
              initialValues={getFormOneData()}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId='formPropertyType'>
                      <Form.Label>Building type</Form.Label>
                      <Select
                        placeholder='Select ...'
                        options={propertyTypeOptions}
                        name='buildingType'
                        onChange={(value) =>
                          setFieldValue('buildingType', value.label)
                        }
                      />
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formBuildingFoundation'>
                      <Form.Label>Foundations</Form.Label>
                      <Select
                        placeholder='Select ...'
                        options={propertyFoundationOptions}
                        name='foundations'
                        onChange={(value) =>
                          setFieldValue('foundations', value.label)
                        }
                      />
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formBuildingWallMaterial'>
                      <Form.Label>External wall material</Form.Label>
                      <Select
                        placeholder='Select ...'
                        options={propertyWallMaterialOptions}
                        name='externalWallMaterial'
                        onChange={(value) =>
                          setFieldValue('externalWallMaterial', value.label)
                        }
                      />
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formBuildingRoofMaterial'>
                      <Form.Label>Roof material</Form.Label>
                      <Select
                        placeholder='Select ...'
                        options={propertyRoofMaterialOptions}
                        name='roofMaterial'
                        onChange={(value) =>
                          setFieldValue('roofMaterial', value.label)
                        }
                      />
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formBuildingConstructionStandard'>
                      <Form.Label>Construction standard</Form.Label>
                      <Select
                        placeholder='Select ...'
                        options={propertyConstructionStandardOptions}
                        name='constructionStandard'
                        onChange={(value) =>
                          setFieldValue('constructionStandard', value.label)
                        }
                      />
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formBuildingSlopeOfLand'>
                      <Form.Label>Slope of land</Form.Label>
                      <Select
                        placeholder='Select ...'
                        options={propertySlopeOfLandOptions}
                        name='slopeOfLand'
                        onChange={(value) =>
                          setFieldValue('slopeOfLand', value.label)
                        }
                      />
                    </Form.Group>
                    <br />
                    <Button type='submit'>Next</Button>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </>
    );
  }

  if (step === '2') {
    return (
      <>
        <Row>
          <Col>
            <p>
              Property profile for: <br />
              <strong>{scoreInput.address}</strong>
            </p>
            <Formik
              enableReinitialize={true}
              onSubmit={submitFormStepTwo}
              initialValues={getFormTwoData()}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId='formPropertyNumberOfLevels'>
                      <Form.Label>Number of levels</Form.Label>
                      <Select
                        placeholder='Select ...'
                        options={propertyNumberOfLevelsOptions}
                        name='numberOfLevels'
                        onChange={(value) =>
                          setFieldValue('numberOfLevels', value.label)
                        }
                      />
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formNumberOfBedrooms'>
                      <Form.Label>Number of Bedrooms and Studies</Form.Label>
                      <Select
                        placeholder='Select ...'
                        options={propertyNumberOfBedroomsOptions}
                        name='numberOfBedrooms'
                        onChange={(value) =>
                          setFieldValue('numberOfBedrooms', value.label)
                        }
                      />
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formSizeOfMainBedroom'>
                      <Form.Label>Size of main bedroom</Form.Label>
                      <Select
                        placeholder='Select ...'
                        options={propertySizeOfMainBedroomOptions}
                        name='sizeOfMainBedroom'
                        onChange={(value) =>
                          setFieldValue('sizeOfMainBedroom', value.label)
                        }
                      />
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formNumberOfBathrooms'>
                      <Form.Label>Number of bathrooms</Form.Label>
                      <Select
                        placeholder='Select ...'
                        options={propertyNumberOfBathroomsOptions}
                        name='numberOfBathrooms'
                        onChange={(value) =>
                          setFieldValue('numberOfBathrooms', value.label)
                        }
                      />
                    </Form.Group>
                    <br />
                    <Form.Group controlId='formNumberOfCarSpaces'>
                      <Form.Label>Number of covered car spaces</Form.Label>
                      <Select
                        placeholder='Select ...'
                        options={propertyNumberOfCoveredCarSpacesOptions}
                        name='numberOfCarSpaces'
                        onChange={(value) =>
                          setFieldValue('numberOfCarSpaces', value.label)
                        }
                      />
                    </Form.Group>
                    <br />
                    <Button type='submit'>Next</Button>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <PropertySelector />
      <br />
      <Row>
        <Col>
          <h4>Property Health Score</h4>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Score>{score}</Score>
          <ScoreSummary>out of 10</ScoreSummary>
          <br />
          <Scale>(confidence: {confidence})</Scale>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <p>
            The Health Score for this property is low|medium|hight at {score}{' '}
            based on available data. More information is needed to provide a
            more accurate Score.
          </p>
          <p>
            Good property health means lower maintenance costs, lower insurance
            fees, and higher property value.
          </p>
          <p>Property Owners can improve the Health Score. Learn here how.</p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <PropertyClaimForm>(Claim this property form)</PropertyClaimForm>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <h5>Benefits of your Property Health Score</h5>
          <br />
          <ul>
            <li>
              <strong>Unlock Financial Advantages:</strong> Gain access to
              exclusive, more favourable terms from insurers and banks.
            </li>
            <li>
              <strong>Efficient Tax Management:</strong> Easily generate
              comprehensive tax depreciation schedules, simplifying your
              financial planning and potentially reducing your tax burden.
            </li>
            <li>
              <strong>Secure, Cloud-Based Document Storage:</strong>{' '}
              Effortlessly store and protect important documents like plans,
              contracts, and receipts in a secure cloud environment.
            </li>
            <li>
              <strong>Efficient Document Retrieval:</strong> Quickly find
              exactly what you need, when you need it — from warranties to
              contracts — with our advanced search tools.
            </li>
            <li>
              <strong>Enhance Your Property's Marketability:</strong> A
              well-documented Property Logbook significantly increases buyer
              interest, leading to better sales results.
            </li>
            <li>
              <strong>Streamlined Maintenance Planning:</strong> Our service
              schedule feature allows for easy planning and budgeting of
              property maintenance tasks.
            </li>
            <li>
              <strong>Showcase Your Property's Health Score:</strong> Advertise
              your property's Health Score to potential buyers, demonstrating
              its excellent condition and value
            </li>
          </ul>

          <Button
            onClick={() => {
              navigate('/owner/1');
            }}
          >
            Next
          </Button>
          <br />
          <br />
          <Button
            onClick={() => {
              navigate('/owner/2');
            }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Owner;
