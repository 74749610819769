import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { Auth } from "aws-amplify";
import claimProperty from "../Services/claimProperty";
import addUser from "../Services/addUser";

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const AUTH_USER_TOKEN_KEY = "PB_TOKEN";

const GoogleAuth = () => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const { expiry, propertyId } = useParams();
  const idtoken = sessionStorage.getItem('st');
  const parsedToken = parseJwt(idtoken);
  

  const signIn = async () => {
   const user = {};
   user.email = parsedToken.email;
   user.username = parsedToken.sub;


   const date = new Date();
   const expires_at = expiry * 1000 + date.getTime();

    try {
      const credentials = await Auth.federatedSignIn(
        "cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_8SEDRMibB",
        { token: idtoken, expires_at },
        user
      );

      console.log({credentials});

      sessionStorage.setItem(AUTH_USER_TOKEN_KEY, idtoken);

      console.log(propertyId);

      try{
        await addUser(client, user.username,"");
      }catch(error){
        console.log("returning user");
      }
      

      if(propertyId !== '0'){
        await claimProperty(client, user.username, propertyId);
        localStorage.setItem("selectedProperty", propertyId);
      }

      navigate("/dashboard");
    
    } catch (error) {
      console.log(error);
      //navigate("/login");
    }
  };


  useEffect(() => {
    signIn();
  }, []);

  // const getAWSCredentials = async (googleUser) => {
  //     console.log({googleUser});
  //       const { id_token, expires_at } = googleUser.getAuthResponse();
  //       const profile = googleUser.getBasicProfile();
  //       let user = {
  //           username: profile.getEmail(),
  //           email: profile.getEmail(),
  //           name: profile.getName()
  //       };

  //       const credentials = await Auth.federatedSignIn(
  //           'google',
  //           { token: id_token, expires_at },
  //           user
  //       );

  //       console.log('credentials', credentials);
  //       window.location.href = id ? `/login/${id}` : "/login";
  //   }

  return <div>Authenticating with Google</div>;
};

export default GoogleAuth;
